@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/variables';
@import 'styles/media';

.button {
  object-fit: contain;

  display: flex;
  justify-content: flex-start;
  gap: 24px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid $outline;
  color: $darkBlue;
  padding: 16px 24px;
  cursor: pointer;

  transition: $transitionDuration border-color ease-in-out;

  &:hover {
    border-color: $pastelBlue;
  }

  & > svg {
    width: 60px;
    height: 60px;
  }

  & > div {
    flex: 1 1 auto;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  @include laptop {
    padding: 16px 32px;
  }
}

.active {
  position: relative;
  border: 2px solid $pastelBlue;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 0 2px;
    width: 28px;
    background: url('/static/images/checkmark-white.svg'), $pastelBlue;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.productDescription {
  text-align: left;
  @include smallFont;
  color: $text;
}

.productName {
  @include mH5Font;

  @include laptop {
    @include h5Font;
  }
}
