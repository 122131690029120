@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/variables';

.popupContent {
  padding: 24px;
  width: 100%;

  @include tablet {
    padding: 48px 60px;
    width: 660px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  & > span {
    @include h3Font;
    color: $darkBlue;
  }

  & > svg {
    cursor: pointer;
  }
}
