@import 'styles/mixins';

.container {
  display: flex;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }
}

.inputWidth {
  width: 262px;

  @include mobile {
    width: 100%;
  }
}

.phoneNumberPlaceholder {
  input {
    &:focus {
      &::placeholder {
        opacity: 1;
      }
    }
  }

  @include mobile {
    margin-top: 16px;
  }
}
