@import 'styles/colors';
@import 'styles/media';
@import 'styles/mixins';

.email {
  @include pFont;
  color: $pastelBlue;
  text-decoration: none;
}

.email:hover {
  opacity: 0.6;
  cursor: pointer;
}
