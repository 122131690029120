@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/variables';
@import 'styles/media';

.container {
  max-width: 95%;
  background-color: $background;
  padding: 24px 12px 36px;
  z-index: 999;

  @include tablet {
    width: 660px;
    height: 308px;
    padding: 48px 60px;
    border-radius: 8px;
  }
}

.title {
  @include h3Font;
  color: $darkBlue;
  margin-bottom: 24px;
  text-align: start;

  @include tablet {
    @include h1Font;
  }
}

.description {
  @include h6Font;
  font-weight: $regularFontWeight;
  color: $text;
  opacity: 0.6;
  margin-bottom: 24px;
  text-align: start;

  @include tablet {
    margin-bottom: 36px;
  }
}

.button {
  width: 342px;

  @include tablet {
    width: 262px;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: center;

  @include tablet {
    justify-content: flex-end;
  }
}
