@import 'styles/mixins';

.container {
  margin-bottom: 24px;

  & > div:last-child {
    margin-top: 12px;
  }
}

.recommendation {
  display: flex;
  align-items: flex-start;

  & > svg {
    margin-right: 12px;
    min-width: 24px;
  }

  & > span {
    @include h6Font;
    color: $placeholder;
  }
}
