@import 'styles/mixins';

.container {
  @include form;
  margin-bottom: 24px;
}

.virtualNationalIdINputDescription {
  margin-top: 4px;

  & > p {
    @include smallFont;
    font-family: $fontFamily;
    color: $text;
  }
}
