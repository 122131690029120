@import 'styles/mixins';
@import 'styles/media';

.container {
  display: flex;
  padding: 0 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.inProgress {
  background: $white;
  border: 1px solid $inProgressStatus;
  border-radius: 4px;
  color: $inProgressStatus;
}

.approved {
  color: $white;
  background: $green;
  border-radius: 4px;
}

.rejected {
  background: $error;
  border: 1px solid $error;
  border-radius: 4px;
  color: $white;
}
