@import 'styles/mixins';
@import 'styles/media';

.container {
  @include form;
  margin-bottom: 24px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
