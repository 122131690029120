.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.buttonContainer {
  margin-left: auto;
}
