@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/media';

.button {
  @include buttonFont;
  background-color: $orange;
  border-radius: 6px;
  padding: 18px 21px 18px 16px;
  color: $white;
  cursor: pointer;
  width: 100%;

  @include tablet {
    width: 262px;
  }
}
