@import 'styles/mixins';

.collapsedHeader {
  @include h4Font;
  color: $orange;
  margin-bottom: 24px;
  display: flex;

  & > span {
    cursor: pointer;
  }
}

.expandedHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  & > span {
    @include h4Font;
    color: $text;
    display: flex;
    align-items: center;

    & > svg {
      margin-left: 12px;
      cursor: pointer;
    }
  }

  & > svg {
    rotate: 180deg;
    cursor: pointer;

    & > path {
      stroke: $darkBlue;
    }
  }
}
