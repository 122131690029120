@import 'styles/colors';
@import 'styles/media';
@import 'styles/mixins';

.container {
  margin-top: 24px;
  z-index: 1;

  @include tablet {
    margin-top: 60px;
  }
}

.title {
  @include mH1Font;
  color: $white;

  @include tablet {
    @include h1Font;
  }
}

.description {
  @include pFont;
  color: $white;
  opacity: 0.6;
  margin-top: 16px;

  @include tablet {
    margin-top: 24px;
  }
}

.sublinksContainer {
  margin-top: 16px;
  display: flex;
  flex-flow: row nowrap;
  overflow: auto;
  padding: 16px 0;

  @include tablet {
    flex-flow: column wrap;
    margin-top: 48px;
    overflow: unset;
    padding: 0;
  }
}

.sublink {
  @include h6Font;
  color: $white;
  opacity: 0.5;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  white-space: nowrap;
}

.sublink + .sublink {
  margin-left: 24px;

  @include tablet {
    margin-top: 24px;
    margin-left: 0;
  }
}

.sublinkActive {
  color: $orange;
  opacity: 1;
}

.sublinkCompleted {
  opacity: 1;
}

.sublinkCompleted:hover,
.sublink:hover {
  color: $pastelBlue;
  cursor: pointer;
}

.sublinkActive:hover {
  cursor: default;
  color: $orange;
}

.disabled {
  pointer-events: none;
}

.icon {
  margin-right: 8px;
}

.linksContainer {
  display: none;

  @include tablet {
    display: flex;
    flex-direction: column;
    margin: 0 -48px;
  }
}

.linksContainerMobile {
  display: flex;
  flex-direction: column;
  margin: 0 -24px;

  @include tablet {
    display: none;
  }
}

.link {
  @include mH5Font;
  color: $white;
  padding: 16px 24px;
  border-top: 1px solid rgba($pastelBlue, 0.2);
  display: flex;
  align-items: center;

  @include tablet {
    border-top: none;
    padding: 16px 48px;
  }
}

.link:hover {
  @include tablet {
    opacity: 0.6;
    cursor: pointer;
  }
}

.linkActive {
  @include tablet {
    border-left: 6px solid $orange;
    padding-left: 42px;
  }
}

.expanded {
  transform: rotate(180deg);
  transition: all 500ms;
}

.linkIcon {
  margin-left: auto;
  transition: all 500ms;

  @include tablet {
    display: none;
  }
}

.status {
  margin-left: 12px;
}

/** Animation **/
.enter {
  opacity: 0;
  max-height: 0;
}

.enterActive {
  opacity: 1;
  max-height: 60px;
  transition: all 500ms;
}

.exit {
  opacity: 1;
  max-height: 60px;
}

.exitActive {
  opacity: 0;
  max-height: 0;
  transition: all 500ms;
}
