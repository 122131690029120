@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/variables';

.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;

  input {
    @include input;
    padding: 24px 16px 8px;

    &:hover:not(:disabled) {
      border-color: $outlinesDark;
    }

    &:focus,
    &:not(:placeholder-shown) {
      & + label {
        @include smLabel;
        color: $placeholder;
        top: 0;
        transform: translate(16px, 8px);
      }
    }

    &::placeholder {
      opacity: 0;
    }

    &:disabled {
      background-color: $background;
      opacity: 0.8;
    }

    & + label {
      @include pFont;
      position: absolute;
      color: $placeholder;
      top: 0;
      left: 0;
      z-index: 99;
      transform: translate(16px, 16px);
      transition: all $transitionDuration ease-in-out;
    }
  }

  &.error > input {
    border-color: $error;
    background-color: rgba($error, 0.12);

    & + label {
      color: $error;
    }
  }

  label {
    pointer-events: none;
  }
}

.optional {
  @include pFont;
  position: absolute;
  color: $outlinesDark;
  right: 16px;
  top: 16px;
}
