@import 'styles/variables';
@import 'styles/mixins';
@import '~reset-css/sass/reset';
@import '@fontsource/dm-sans';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

button {
  outline: none;
  border: none;
  background-color: transparent;
}

#root {
  @include fontFamily;
  min-height: 100vh;
}
