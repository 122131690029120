@import 'styles/colors';
@import 'styles/media';
@import 'styles/mixins';

.container {
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  background-color: $pastelOrange;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.sabiLogo {
  position: absolute;
  left: 24px;
  top: 24px;

  @include tablet {
    left: 48px;
    top: 36px;
  }
}

.sunImage {
  max-width: 357px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;

  @include tablet {
    max-width: 377px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
}

.title {
  @include h2Font;
  font-weight: $boldFontWeight;
  color: $darkBlue;
}

.subTitle {
  @include h2Font;
  color: $darkBlue;
}

.description {
  margin-top: 16px;
  margin-bottom: 36px;
  @include pFont;
  color: $text;
  opacity: 0.6;

  @include tablet {
    max-width: 320px;
  }
}

.button {
  width: 100%;

  @include tablet {
    max-width: 260px;
  }
}

.email {
  position: absolute;
  bottom: 24px;
  left: 24px;
  color: $orange;

  @include tablet {
    left: 48px;
    bottom: 36px;
  }
}
