@import 'styles/mixins';
@import 'styles/media';

$border: 1px solid $outline;

.container {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  background: $white;
  border: $border;
  border-radius: 4px;
  margin-bottom: 16px;
}

.header {
  @include h5Font;
  color: $text;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  & span:last-child {
    font-weight: $boldFontWeight;
  }
}

.statusInfo {
  border-bottom: $border;
  padding-bottom: 12px;
  display: flex;
  align-items: center;

  & span:first-child {
    @include smallFont;
    color: $placeholder;
    margin-right: 8px;
  }
}

.loanInfo {
  @include h6Font;
  border-bottom: $border;
  padding: 12px 0;
  display: flex;
  align-items: center;
  color: $text;

  & div:first-child {
    color: $placeholder;
    width: 219px;
    margin-right: 11px;
  }

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.button {
  margin-top: 12px;

  & span {
    @include h6Font;
    color: $orange;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
}
