@import 'styles/mixins';

.container {
  @include h2Font;
  color: $darkBlue;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  position: relative;

  @include mobile {
    @include mH2Font;
  }
}

.backButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;

  @include tablet {
    position: absolute;
    left: -12px;
    top: 21px;
    transform: translate(-100%, -50%);
    margin-bottom: 0;
    cursor: pointer;
  }
}

.label {
  display: inline-block;
  margin-left: 4px;
  @include buttonFont;
  color: $darkBlue;

  @include tablet {
    display: none;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
