@import 'styles/colors';
@import 'styles/media';
@import 'styles/mixins';

.notVerifiedMessage {
  @include h6Font;
  color: $text;

  margin-bottom: 36px;
}
