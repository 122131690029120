@import 'styles/variables';
@import 'styles/colors';
@import 'styles/media';

@mixin fontFamily {
  font-family: $fontFamily;
  font-style: normal;
}

@mixin h1Font {
  font-weight: 400;
  font-size: 44px;
  line-height: 48px;
}

@mixin h2Font {
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
}

@mixin h3Font {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
}

@mixin h4Font {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

@mixin h5Font {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

@mixin h6Font {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin pFont {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin smallFont {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

@mixin buttonFont {
  @include fontFamily;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

@mixin smLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

@mixin tagFont {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

@mixin mH1Font {
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
}

@mixin mH2Font {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
}

@mixin mH3Font {
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
}

@mixin mH4Font {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
}

@mixin mH5Font {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

@mixin mH6Font {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

@mixin input {
  @include pFont;
  font-family: $fontFamily;
  min-height: 56px;
  width: 100%;
  box-sizing: border-box;
  transition: all $transitionDuration ease-in-out;
  border: 1px solid $outline;
  border-radius: 6px;
  color: $text;
  opacity: 0.8;
}

@mixin form {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
}

@mixin label {
  @include pFont;
  position: absolute;
  color: $placeholder;
  top: 0;
  left: 0;
  z-index: 99;
  transform: translate(16px, 16px);
  transition: all $transitionDuration ease-in-out;
}

@mixin floatedLabel {
  @include smLabel;
  position: absolute;
  color: $placeholder;
  top: 0;
  transform: translate(16px, 8px);
}
