@import 'styles/mixins';
@import 'styles/media';

.container {
  @include form;
  margin-bottom: 24px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;

  @include mobile {
    width: 100%;
  }
}
