$darkBlue: #02075c;
$pastelBlue: #9fc8ea;
$background: #f6f7f9;
$pastelPurple: #b599dd;
$pastelOrange: #fefaf6;
$pastelPeach: #ffc690;
$green: #0ac610;
$orange: #ff7f36;
$white: #ffffff;
$placeholder: #79818c;
$text: #252631;
$outline: #d2d5dd;
$outlinesDark: #a8acc3;
$error: #f53c27;
$inProgressStatus: #3a5157;
$draggableFieldBackground: rgba(246, 247, 249, 0.5);
