@import 'styles/colors';
@import 'styles/media';
@import 'styles/mixins';

.content {
  display: flex;
  flex-direction: column;
  padding: 16px 0 36px;
}

.title {
  @include mH6Font;
  color: $darkBlue;
  margin-bottom: 16px;

  @include tablet {
    @include h6Font;
  }
}

.row {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  border-top: 1px solid $outline;

  @include tablet {
    flex-direction: row;
    align-content: flex-start;
    @include h6Font;
  }
}

.name {
  width: 100%;
  @include pFont;
  color: $placeholder;

  @include tablet {
    max-width: 242px;
  }
}

.value {
  width: 100%;
  @include pFont;
  color: $text;
  display: flex;
  align-items: center;

  @include tablet {
    margin-left: 12px;
  }
}

.icon {
  margin-right: 6px;
}

.row:last-of-type {
  border-bottom: 1px solid $outline;
}
