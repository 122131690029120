@import 'styles/colors';
@import 'styles/media';
@import 'styles/mixins';

.container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.sidebar {
  max-width: 100vw;
  width: 100%;
  min-height: 100%;
  background-color: $darkBlue;
  padding: 24px;
  position: static;
  display: flex;
  flex-direction: column;

  @include tablet {
    max-width: 400px;
    padding: 36px 48px;
    position: fixed;
  }
}

.childrenWrapper {
  width: 100%;
  height: 100%;
  background-color: $background;
  display: flex;
  justify-content: center;
  position: static;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25);
  width: auto;
  z-index: 2;
  margin: 0 -12px 24px;

  @include tablet {
    position: absolute;
    width: calc(100vw - 100%);
    top: 0;
    left: 100%;
    height: 100%;
    margin: 0;
    overflow-x: hidden;
  }
}

.childrenContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 12px;

  @include tablet {
    min-height: 100%;
    height: max-content;
    padding: 48px 0 56px;
    display: flex;
    align-items: center;
    justify-content: start;
  }
}

.childrenContent {
  max-width: 540px;
  margin: 0 auto;
  width: 100%;

  @include mobile {
    width: 100%;
  }
}

.email {
  margin-top: auto;
  z-index: 1;
  align-self: center;

  @include tablet {
    align-self: flex-start;
  }
}

.sabiIcon {
  max-width: 357px;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.06;

  @include tablet {
    max-width: 377px;
  }
}
