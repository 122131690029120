@import 'styles/colors';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.content {
  background-color: $white;
  border-radius: 4px;
  z-index: 1000;
}
