@import 'styles/colors';
@import 'styles/media';
@import 'styles/mixins';

.container {
  color: $white;
  @include tagFont;
  padding: 3px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;

  @include tablet {
    @include buttonFont;
  }

  width: max-content;
}

.inProgress {
  background-color: $pastelBlue;
}

.inProgress.transparent {
  color: $pastelBlue;
  border: 1px solid $pastelBlue;
}

.verified {
  background-color: $green;
}

.verified.transparent {
  color: $green;
  border: 1px solid $green;
}

.rejected {
  background-color: $error;
}

.rejected.transparent {
  color: $error;
  border: 1px solid $error;
}

.transparent {
  background-color: transparent;

  @include tablet {
    @include tagFont;
  }
}

.icon {
  width: 18px;
  height: 18px;
  margin-right: 2px;
  margin-left: -3px;
}

.transparent .icon {
  & > path {
    stroke: $green;
  }
}
