@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/variables';

.container {
  position: relative;
  margin-bottom: 12px;
}

.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  background: $draggableFieldBackground;
  border: 1px dashed $outline;
  border-radius: 4px;
  margin-top: 8px;
  transition: $transitionDuration border ease-in-out;
}

.dragOverInputWrapper {
  @extend .inputWrapper;
  border: 1px dashed $outlinesDark;
}

.fileInput {
  display: none;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    @include pFont;
    color: $placeholder;
    text-align: center;
  }
}

.browse {
  color: $orange;
  cursor: pointer;
}

.errorInputWrapper {
  border-color: $error;
  background-color: rgba(245, 60, 39, 0.04);
}
