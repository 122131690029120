@import 'styles/colors';
@import 'styles/media';

.button {
  background-color: $background;
  border: 1px solid $outline;

  @include mobile {
    max-width: 48px;
    padding: 16px 12px;
  }
}

.buttonContent {
  display: flex;
  align-items: center;
  color: $text;

  & > span {
    opacity: 0.5;
  }

  & > svg {
    margin-right: 4px;
  }

  @include mobile {
    & > span {
      display: none;
    }

    & > svg {
      margin-right: 0;
    }
  }
}
