@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/variables';

.container {
  position: relative;
}

.input {
  background-color: $white;
}

.label {
  @include pFont;
  position: absolute;
  pointer-events: none;
  z-index: 1;
  color: $placeholder;
  transform: translate(16px, 16px);
  transition: all $transitionDuration ease-in-out;
}

.labelFloating {
  @include smLabel;
  color: $placeholder;
  transform: translate(16px, 8px);
}

.iconContainer {
  position: absolute;
  left: 11px;
}

.optionContainer {
  margin: 0 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > svg {
    & > path {
      stroke: $orange;
    }
  }
}

.option {
  display: flex;
  align-items: center;
}

.arrowIcon {
  & > path {
    opacity: 0.4;
    stroke: $darkBlue;
  }
}

.arrowIconRotate {
  rotate: 180deg;
}

.errorLabel {
  color: $error;
}
