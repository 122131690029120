.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  & > svg {
    & :nth-child(3) {
      transform-origin: top center;
      transform: rotate(3deg);
      animation: swing 2s infinite;
    }

    & :nth-child(5) {
      stroke-dasharray: 39;
      stroke-dashoffset: 39;
      animation: steamLarge 2s infinite;
    }

    & :nth-child(6) {
      stroke-dasharray: 27;
      stroke-dashoffset: 27;
      animation: steamSmall 2s infinite;
    }
  }
}

@keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@keyframes steamLarge {
  0% {
    stroke-dashoffset: 39;
    opacity: 0.6;
  }

  100% {
    stroke-dashoffset: 13;
    opacity: 0;
  }
}

@keyframes steamSmall {
  10% {
    stroke-dashoffset: 27;
    opacity: 0.6;
  }

  80% {
    stroke-dashoffset: 9;
    opacity: 0;
  }

  100% {
    stroke-dashoffset: 9;
    opacity: 0;
  }
}
