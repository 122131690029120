@import 'styles/colors';
@import 'styles/media';
@import 'styles/mixins';

.itemBtn {
  cursor: pointer;
  align-items: center;
  width: 100%;
  margin: 0;
  text-align: left;
  background-color: transparent;
  border: none;
  display: flex;
  color: $darkBlue;
  @include mH4Font;
  @include fontFamily;

  @include tablet {
    @include h4Font;
  }
}

.item + .item {
  margin-top: 16px;
}

.itemContent {
  transition: height 0.4s ease-in-out;
}

.icon {
  width: 24px;
  height: 24px;
  margin-left: auto;
  transition: transform 0.4s ease-in-out;

  & > path {
    stroke: $darkBlue;
  }
}

.expanded .icon {
  transform: rotate(180deg);
}
