@import 'styles/mixins';
@import 'styles/media';

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.formBottomPadding {
  padding-bottom: 24px;
}

.representativeInputMarginBottom {
  margin-bottom: 26px;
}

.transition {
  transition-property: max-height, padding-bottom, padding-top;
  will-change: max-height, padding-bottom, padding-top;
  transition-timing-function: ease-out;
}
