@import 'styles/mixins';
@import 'styles/media';

.container {
  @include form;
  margin-bottom: 24px;
}

.title {
  margin-bottom: 8px;
  @include h4Font;
  color: $darkBlue;
  display: none;

  @include tablet {
    display: block;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    width: 100%;
  }
}
