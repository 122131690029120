@import 'styles/mixins';
@import 'styles/colors';

.wrapper {
  max-width: inherit;
  overflow: hidden;
}

.container {
  @include input;
  background-color: $white;
  opacity: 1;
  padding: 16px 16px 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  max-height: 56px;
  max-width: inherit;
  overflow: hidden;
}

.disabledContainer {
  background-color: $background;
  opacity: 0.8;
  pointer-events: none;
}

.containerError {
  border-color: $error;
  background-color: rgba($error, 0.12);
}

.label {
  @include label;
}

.labelFloated {
  @include floatedLabel;
}

.labelError {
  color: $error;
}

.closeIcon {
  cursor: pointer;
  width: 24px;
}

.upload {
  display: flex;
  align-items: center;

  & > span {
    @include mH6Font;
    color: $orange;
    margin-left: 8px;
  }
}

.valuesContainer {
  @include pFont;
  color: $text;
  align-items: center;
  margin-left: 16px;
  top: 8px;
  position: relative;
  max-width: 75%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
