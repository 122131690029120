@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.fileItemContainer {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  margin-top: 8px;
  border-bottom: 1px solid $outline;
}

.fileData {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.fileName {
  @include h5Font;
  color: $text;
}

.fileFormat {
  @include h6Font;
  color: $placeholder;
}

.closeIcon {
  width: 24px;
  position: absolute;
  right: 0;
  cursor: pointer;
}
