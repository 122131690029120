@import 'styles/colors';
@import 'styles/media';
@import 'styles/mixins';

.container {
  background-color: $darkBlue;
  min-height: 100vh;
  position: relative;
}

.childrenContainer {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
}

.sabiLogo {
  position: absolute;
  left: 24px;
  top: 24px;

  @include tablet {
    left: 48px;
    top: 36px;
  }
}

.email {
  @include pFont;
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  @include tablet {
    left: 48px;
    bottom: 36px;
    right: auto;
  }
}

.sabiIcon {
  max-width: 357px;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.06;

  @include tablet {
    max-width: 377px;
  }
}
