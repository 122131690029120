@import 'styles/colors';
@import 'styles/media';
@import 'styles/mixins';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  margin-right: auto;
}

.logoutButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $white;
  font-weight: $boldFontWeight;
}

.logoutButton:hover {
  cursor: pointer;
  opacity: 0.6;
}

.logout {
  margin-left: 12px;
}
