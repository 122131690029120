@import 'styles/colors';
@import 'styles/media';
@import 'styles/mixins';

.container {
  @include h6Font;
  background: $white;
  color: $orange;
  border: 1px dashed $outline;
  border-radius: 4px;
  width: 100%;
  padding: 36px 0;
  margin-bottom: 24px;
  text-align: center;
  cursor: pointer;
}
